<template>
  <Page full>
    <ProjectsBody />
  </Page>
</template>

<script>
import PageMixin from '../mixins/Page'
import ProjectsBody from '../bodies/Projects.vue'

export default {
  mixins: [PageMixin],
  components: {
    ProjectsBody
  }
}
</script>
