<template>
  <grid
    type="quote"
    title="Projects"
    :multiple="true"
    :isMain="isMain"
    :order="[['quote_time_last_modified', 'DESC']]"
    :filters="{
      quote_status: 'k||f'
    }"
    :permanentFilters="{
      quote_state: '!lead'
    }"
    :visible="[
      'quote_preview',
      'nextSteps',
      'quote_time_created',
      'quote_time_last_modified',
      'quote_status',
      'quote_price_net',
      'quote_owner'
    ]"
    :filterPresets="[
      {
        title: 'Estimates',
        description: 'Projects in the estimation / proposal phase',
        filters: {
          quote_status: 'p'
        },
        filterText: {
          quote_status: 'Pending'
        }
      },
      {
        title: 'Booked or in progress',
        description: 'Projects that have been booked or started',
        filters: {
          quote_status: 'k||f'
        },
        filterText: {
          quote_status: 'Booked or in progress'
        }
      },
      {
        title: 'Booked',
        description: 'Projects that have been booked, but not started yet',
        filters: {
          quote_status: 'k'
        },
        filterText: {
          quote_status: 'Booked'
        }
      },
      {
        title: 'In-progress',
        description: 'Projects that are currently in-progress',
        filters: {
          quote_status: 'f'
        },
        filterText: {
          quote_status: 'In progress'
        }
      },
      {
        title: 'Completed',
        description: 'Projects that have been completed or closed',
        filters: {
          quote_status: 'g'
        },
        filterText: {
          quote_status: 'Completed'
        }
      }
    ]"
  >
  </grid>
</template>

<script>
import BodyMixin from '../mixins/Body'
import Grid from '@/components/ui/Grid/Grid.vue'

/**
 * Modifier classes:
 *  -Scrollable - making container holding projects scrollable independantly
 *
 */
export default {
  components: { Grid },
  mixins: [BodyMixin],
  data() {
    return {
      set: [],
      loading: 0,
      searchPhraseLocal: this.searchPhrase
    }
  },
  methods: {
    reload() {
      this.$refs.progress.reload()
      this.$refs.booked.reload()
    }
  },
  computed: {
    inProgressSum() {
      return this.inProgressProjects.reduce((sum, p) => sum + p.quote_price_net, 0)
    },
    bookedSum() {
      return this.bookedProjects.reduce((sum, p) => sum + p.quote_price_net, 0)
    },
    inProgressCount() {
      return this.inProgressProjects.length
    },
    bookedCount() {
      return this.bookedProjects.length
    },
    inProgressProjects() {
      return this.set.filter((p) => p.quote_status === 'f')
    },
    bookedProjects() {
      return this.set.filter((p) => p.quote_status === 'k')
    }
  },
  props: {
    searchPhrase: {
      default: ''
    }
  }
}
</script>

<style lang="scss" rel="stylesheet/scss"></style>
